import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { EncryptionService, SlidePanelService } from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistManageNewComponent } from '../../playlist-editor/playlist-manage-new/playlist-manage-new.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { PlaylistStore } from '@desquare/stores';
import { map } from 'rxjs';
@Component({
  standalone: true,
  imports: [SlidePanelComponent, PlaylistManageNewComponent],
  selector: 'app-playlist-manage-panel',
  template: `
    <app-slide-panel
      (closePanel)="closePlaylistManagePanel()"
      [fullscreenTooltip]="'TOGGLE_PLAYLIST_TABLE'"
      [closePanelTooltip]="'CLOSE_THIS_PLAYLIST'"
      [openWidth]="20"
      [fullscreen]="true"
      [panelContent]="'playlistManage'"
    >
      <designage-playlist-manage [createPlaylist]="createPlaylist()" />
    </app-slide-panel>
  `,
})
export class PlaylistManagePanelComponent {
  route = inject(ActivatedRoute);
  playlistStore = inject(PlaylistStore);
  modalService = inject(NgbModal);
  translateService = inject(TranslateService);

  router = inject(Router);
  encryptionService = inject(EncryptionService);

  showTableBelowPlaylist: boolean = false;
  isPlaylistManagePanelCollapsed: boolean = true;
  isOkToRemovePendingPlaylist: boolean = false;
  playlistId = signal<string>('');
  params$ = this.route.params.pipe(takeUntilDestroyed());
  createPlaylist = toSignal(
    this.route.data.pipe(
      takeUntilDestroyed(),
      map((data) => data['createPlaylist']),
    ),
  );

  constructor() {
    this.params$.subscribe((params) => {
      const { id } = params;
      if (id) this.playlistId.set(this.encryptionService.decrypt(id));
    });
    inject(SlidePanelService).setPanelComponentId(this.playlistId());
  }

  async canDeactivate(): Promise<boolean> {
    return this.playlistStore.isDirty()
      ? this.openUnsavedConfirmationDialog()
      : true;
  }

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page?';
    modalRef.componentInstance.bodyText = this.translateService.instant(
      'CLOSE_WITHOUT_SAVING_PROMPT',
    );
    modalRef.componentInstance.dismissButtonText = 'CANCEL';
    modalRef.componentInstance.closeButtonText = 'CLOSE_WITHOUT_SAVING';
    modalRef.componentInstance.useDangerButton = true;

    return await modalRef.result.then(
      (value) => {
        return value;
      },
      () => {
        return false;
      },
    );
  }

  async closePlaylistManagePanel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
    // this.router.navigate(['../../'], { relativeTo: this.route }).then(() => {
    //   this.playlistStore.reset();
    // });
  }

  removePendingPlaylist() {
    /*this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: this.playlistId,
    });*/
  }
}
