import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Location } from '@angular/common';
import {
  GetIsInitializingAppGQL,
  WriteIsInitializingAppToCacheGQL,
} from '@designage/gql';
import {
  CurrentUserService,
  ResponsiveUiService,
  RoutingStateService,
  SessionService,
} from '@desquare/services';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { languageConstants } from '@desquare/constants';
import { languageUtil, routeUtil } from '@desquare/utils';
import { NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { catchError, EMPTY, lastValueFrom, retry, switchMap, tap } from 'rxjs';
import { fadeOutOnLeaveAnimation } from 'angular-animations';
import { InititalizationPageComponent } from './main/initialization-page/inititalizationPage.component';
import { environment } from '@desquare/environments';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

@Component({
  standalone: true,
  imports: [InititalizationPageComponent, RouterOutlet],
  selector: 'app-designage',
  template: `
    @if (initializing || !initAnimDone) {
      <designage-inititalization-page
        (initAnimDone)="setInitAnimDone($event)"
        [@fadeOutOnLeave]
      />
    } @else {
      <router-outlet></router-outlet>
    }
  `,
  styleUrls: ['./app.component.scss'],
  animations: [fadeOutOnLeaveAnimation()],
  providers: [{ provide: NgbTooltipConfig }],
})
export class AppComponent implements OnInit, OnDestroy {
  setIsInitializingAppGQL = inject(WriteIsInitializingAppToCacheGQL);
  getIsInitializingAppGQL = inject(GetIsInitializingAppGQL);
  responsiveUiService = inject(ResponsiveUiService);
  private subs = new SubSink();

  initializing = true;
  initialLocation = '';
  initAnimDone = false;
  loginRequired: boolean;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private routingStateService: RoutingStateService,
    public ngbConfigTooltip: NgbTooltipConfig,
    public ngbConfigPopover: NgbPopoverConfig,
    private currentUserService: CurrentUserService,
  ) {
    languageUtil.registerLanguages();
    ngbConfigTooltip.container = 'body';
    ngbConfigTooltip.animation = true;
    ngbConfigTooltip.placement = 'bottom auto';
    ngbConfigTooltip.triggers = 'hover click';
    ngbConfigTooltip.openDelay = 1500;
    ngbConfigTooltip.closeDelay = 100;
    ngbConfigTooltip.disableTooltip =
      (this.responsiveUiService.isMobileDevice() ||
        this.responsiveUiService.isTabletScreen()) ??
      false;
    ngbConfigTooltip.tooltipClass = 'ngbTooltipClass';
    ngbConfigTooltip.autoClose = true;

    ngbConfigPopover.container = 'body';
    ngbConfigPopover.animation = true;
    ngbConfigPopover.placement = 'bottom auto';

    this.loginRequired = !routeUtil.isPublicAppRoute(this.location.path());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async ngOnInit() {
    this.initVariables();
    this.initAppStates();
    this.initSubscriptions();

    await this.initializeTranslations();
    this.remove1password();

    // if (typeof Worker !== 'undefined') {
    //   // Create a new
    //   const worker = new Worker(
    //     new URL('./devices-worker.worker', import.meta.url)
    //   );
    //   worker.onmessage = ({ data }) => {
    //     console.log(`page got message ${data}`);
    //   };
    //   worker.postMessage('hello');
    // } else {
    //   // Web Workers are not supported in this environment.
    //   // You should add a fallback so that your program still executes correctly.
    // }
    if (!environment.production) {
      // Adds messages only in a dev environment
      loadDevMessages();
      loadErrorMessages();
    }
  }

  initVariables() {
    this.sessionService.initAppName('cms');
    this.setIsInitializingApp(true);
    this.initialLocation = this.location.path();
  }

  initSubscriptions() {
    this.subs.sink = this.getIsInitializingApp().subscribe();
    this.subs.sink = this.loginIfRequired()
      .pipe(
        switchMap(async (isAuthenticated) => {
          if (isAuthenticated) {
            await this.currentUserService.initCurrentUser();
            this.setIsInitializingApp(false);
            // return this.fetchActiveUserDetails();
            return;
          } else {
            this.setIsInitializingApp(false);
            return EMPTY;
          }
        }),
        catchError((error) => {
          console.warn('Encountered an error, will retry after 4sec.');
          throw new Error(error);
        }),
        retry({
          delay: 4000, // 4sec.
        }),
      )
      .subscribe();
  }

  setInitAnimDone(event: boolean) {
    this.initAnimDone = event;
  }

  /** if current url requires login AND if Auth0 is not authenticated (or logs out) redirects to login, returns Auth0.isAuthenticated$ observable */
  loginIfRequired() {
    return this.sessionService.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          if (this.loginRequired) {
            const { origin, pathname, search } = window.location;
            // const redirectUri = `${origin}/auth0-callback`;
            const target = `${pathname}${search}`;

            // console.error('SHOULD LOGIN');
            // return;
            this.sessionService.initLogin(origin);
            this.sessionService.login(target);
            /* this.authService.loginWithRedirect({
            authorizationParams: {
              audience: environment.auth0.apiAudience,
              redirect_uri: redirectUri,
            },
            // redirect_uri: redirectUri,
            appState: { target }, // https://community.auth0.com/t/redirect-uri-issue-after-successful-login-with-auth0-auth0-angular/58111/2
          });*/
          }
        }
      }),
      // tap((x) => console.log('isAuthenticated: ', x)) // DEBUG
    );
  }

  getIsInitializingApp() {
    return this.getIsInitializingAppGQL.watch().valueChanges.pipe(
      tap(({ data: { isInitializingApp } }) => {
        this.initializing = !!isInitializingApp;
      }),
    );
  }

  initAppStates() {
    this.routingStateService.initRoutingState();
  }

  async initializeTranslations() {
    this.translateService.addLangs(languageConstants.SUPPORTED_LANGUAGES);
    this.translateService.setDefaultLang('en');

    const browserLanguage = this.translateService.getBrowserLang();
    const language =
      browserLanguage &&
      languageConstants.SUPPORTED_LANGUAGES.indexOf(browserLanguage) >= 0
        ? browserLanguage
        : 'en';

    await lastValueFrom(this.translateService.use(language));
  }

  navigateAfterInit() {
    if (!this.isAuth0CallbackRoute(this.location.path())) {
      /*if (
        (this.location.path() === '' && this.initialLocation === '') ||
        this.isAuth0CallbackRoute(this.initialLocation)
      ) {
        this.router.navigate(['/watchtower']);
      } else {*/
      this.router.navigateByUrl(this.initialLocation);
      /* } */
    }
  }

  isAuth0CallbackRoute(path: string) {
    return path.includes('/auth0-callback');
  }

  setIsInitializingApp(value: boolean) {
    this.subs.sink = this.setIsInitializingAppGQL
      .mutate({ input: value })
      .subscribe();
  }

  remove1password() {
    new MutationObserver((mutations) => {
      for (const { addedNodes } of mutations) {
        for (const node of addedNodes) {
          if (
            node.nodeName === 'COM-1PASSWORD-BUTTON' ||
            node.nodeName === 'COM-1PASSWORD-MENU'
          ) {
            node.parentNode?.removeChild(node);
            console.log('Removed 1Password'); // TODO: Remove
          }
        }
      }
    }).observe(document.body, { childList: true });
  }
}
