import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({ name: 'numberShorten', standalone: true })
export class NumberShortenPipe implements PipeTransform {
  transform(
    bytes: number | string,
    type: string = 'bps',
    precision: number = 2,
  ): string {
    const units = [
      `${type}`,
      `K${type}`,
      `M${type}`,
      `G${type}`,
      `T${type}`,
      `P${type}`,
    ];
    if (typeof bytes === 'string') {
      bytes = parseFloat(bytes);
    }
    if (isNaN(bytes) || !isFinite(bytes)) return '?';

    let unit = 0;

    while (bytes >= 1000) {
      bytes /= 1000;
      unit++;
    }

    return bytes.toFixed(+precision) + ' ' + units[unit];
  }
}
