import { setIsInitializingApp } from './setIsInitializingApplication.mutation';
import { setActiveProfile } from './setActiveProfile.mutation';
import { setSelectedChannel } from './setSelectedChannel.mutation';
import { setSelectedDevice } from './setSelectedDevice.mutation';
import { CustomResolver } from './common';
import { Mutation } from '@designage/gql';

export const resolvers: {
  Mutation: Partial<Record<keyof Mutation, CustomResolver>>;
} = {
  Mutation: {
    setIsInitializingApp,
    setActiveProfile,
    setSelectedChannel,
    setSelectedDevice,
  },
};
