@if (loading()) {
  <div class="progress progress-bar-custom" @showHidePlaylistLoader>
    <div
      class="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
}
<div class="inspector-panel" [class.disabled]="loading()">
  @if (!channelParent()) {
    <div class="row">
      <div
        class="text-truncate d-flex align-items-center justify-content-between sliding-panel-header"
      >
        <label class="d-inline pointer-text">
          {{ device().name }}
        </label>
      </div>
    </div>
  }
  <!-- MARK: Header -->
  <div class="inspector-panel-header mt-4 mt-xl-2">
    @if (showCloseButton()) {
      <div (click)="closeClick.emit()" class="back-to-list-btn">
        <i class="ri-list-unordered"></i>
      </div>
    }
    <H4 class="flex-grow-1 ps-1">
      {{
        channelParent()
          ? ('DEVICE_NAME' | translate) + ': ' + device().name
          : ''
      }}
    </H4>
    <div class="d-flex lg-me-5">
      <div
        [@StatusGaugeAnimation]
        class="d-flex align-items-center px-2 fs-6 bg-dark"
      >
        <span class="text-nowrap d-none d-sm-block me-2">
          {{ 'STATUS' | translate }}:
        </span>
        <span
          class="px-1 badge"
          [class.bg-success]="device().status?.Status === 1"
          [class.bg-danger]="device().status?.Status === 4"
        >
          {{ device().status?.StatusLabel ?? '' | translate }}
        </span>
      </div>
      @if (device().status?.Status === 1) {
        @if (device().deviceInfo?.screen?.brightness != null) {
          <div
            [@StatusGaugeAnimation]
            class="d-flex align-items-center px-2 fs-6 bg-dark"
          >
            <span class="text-nowrap d-none d-sm-block me-2">
              {{ 'DISPLAY_POWER' | translate }}:
            </span>
            <span>
              @if (device().deviceInfo) {
                <app-device-brightness [deviceInfo]="device().deviceInfo!" />
              }
            </span>
          </div>
        }
        @if (device().deviceInfo?.screen?.volume != null) {
          <div
            [@StatusGaugeAnimation]
            class="d-flex align-items-center px-2 fs-6 bg-dark"
          >
            <span class="text-nowrap d-none d-sm-block me-2">
              {{ 'DEVICE_VOLUME' | translate }}:
            </span>
            <span>
              @if (device().deviceInfo) {
                <app-device-volume [deviceInfo]="device().deviceInfo!" />
              }
            </span>
          </div>
        }
      }
    </div>
  </div>

  @if (responsiveUiService.lg()) {
    <as-split
      direction="horizontal"
      unit="pixel"
      (transitionEnd)="updateView()"
      (dragEnd)="updateView()"
    >
      <as-split-area class="d-flex flex-column">
        <ng-container [ngTemplateOutlet]="screenShotTemplate"></ng-container>
      </as-split-area>
      <as-split-area
        class="d-flex flex-column overflow-hidden"
        minSize="470"
        size="470"
      >
        <ng-container [ngTemplateOutlet]="deviceDetailsTemplate"></ng-container>
      </as-split-area>
    </as-split>
  } @else {
    <ng-container [ngTemplateOutlet]="deviceDetailsTemplate"></ng-container>
  }
  <!-- MARK: Screenshot -->
  <ng-template #screenShotTemplate>
    <div class="border-bottom pt-3">
      <h4 class="mb-1">{{ 'SCREENSHOT' | translate }}</h4>
    </div>
    @if (device().deviceInfo !== null) {
      <app-device-screenshot [device]="device()"></app-device-screenshot>
    } @else {
      <ngx-skeleton-loader
        count="1"
        animation="progress-dark"
        [theme]="{
          height: '25rem',
        }"
      />
    }
  </ng-template>

  <ng-template #deviceDetailsTemplate>
    <nav ngbNav #nav="ngbNav" class="pt-3 nav nav-pills">
      <!-- 
    ** MARK: Table for general details. Read only. 
    ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>Info</a>
        </li>
        <ng-template ngbNavContent>
          <div class="p-2">
            @if (device()) {
              <device-details-table [device]="device()"></device-details-table>
            } @else {
              <div class="p-2 text-center">
                <p>
                  Missing device data. This can be due to the device just being
                  created.
                </p>
                <p>
                  Try to reload the page. If the issue persists, please contact
                  support by clicking the help button in the menu or mail
                  support&#64;designage.io
                </p>
              </div>
            }
          </div>
        </ng-template>
      </ng-container>

      <!-- 
    ** MARK: Table for Device Settings 
    ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'SETTINGS' | translate }}</a>
        </li>
        <ng-template ngbNavContent>
          <app-device-manage-settings
            [device]="device()"
            [saving]="saving()"
            [profileChannels]="profileChannels()"
            [isUserSuperAdmin]="isUserSuperAdmin"
            (save)="saveForm($event, 'settings')"
          />
        </ng-template>
      </ng-container>

      <!-- 
    ** MARK:Location
    ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'LOCATION' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100 d-flex flex-column">
            <div
              style="height: 4rem"
              class="d-flex justify-content-between align-items-center p-2"
            >
              @if (editLocation()) {
                <app-location-selection
                  class="d-block my-2"
                  [profileId]="profileId()"
                  [selectedLocationId]="vm.device.location?.id"
                  [isHideAddLocationButton]="true"
                  [isHideEditLocationButton]="true"
                  (editLocationClicked)="editLocation()"
                  (selectionChange)="setLocation($event)"
                  (createNewLocation)="createLocation($event)"
                />
                <div class="d-flex">
                  <button
                    class="btn btn-sm btn-outline-warning mx-1"
                    (click)="editLocation.set(false)"
                  >
                    {{ 'CANCEL' | translate }}
                  </button>
                  <button
                    class="btn btn-sm btn-outline-success"
                    (click)="saveForm(vm.device, 'location')"
                  >
                    {{ 'SAVE' | translate }}
                  </button>
                </div>
              } @else {
                <h3>{{ device().location?.name }}</h3>
                <button
                  class="btn btn-sm btn-outline-primary"
                  (click)="onEditLocation()"
                >
                  {{ 'CHANGE_LOCATION' | translate }}
                </button>
              }
            </div>
            <div class="location-map-border">
              @if (device().location) {
                <app-location-form
                  #locationComponent
                  class="h-100"
                  [hidden]="loading()"
                  [showMapOnly]="true"
                  [location]="locationForm()"
                />
              }
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
    ** MARK:Statistics
    ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'STATS' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100">
            <div class="location-map-border">
              <!-- <ng-container
              [ngComponentOutlet]="deviceMonitorComponent"
              [ngComponentOutletInputs]="deviceMonitorComponentInputs"
            ></ng-container> -->
              @if (deviceId()) {
                <designage-device-monitor
                  #monitorComponent
                  class="h-100"
                  [deviceId]="deviceId()"
                />
              }
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
    ** MARK: Events
    ** -->
      @if (showEvents) {
        <ng-container ngbNavItem>
          <li>
            <a ngbNavLink>{{ 'EVENTS' | translate }}</a>
          </li>

          <ng-template ngbNavContent>
            <div class="p-2 h-100">
              <div class="location-map-border">
                <app-event-list
                  #eventComponent
                  class="h-100"
                  [hidden]="loading()"
                  [deviceId]="device().id"
                ></app-event-list>
              </div>
            </div>
          </ng-template>
        </ng-container>
      }

      <!-- 
    ** MARK: Logs
    ** -->
      @if (isUserSuperAdmin) {
        <ng-container
          ngbNavItem
          [destroyOnHide]="false"
          (shown)="showDeviceLog = true"
        >
          <li>
            <a ngbNavLink>{{ 'LOGS' | translate }}</a>
          </li>

          <ng-template ngbNavContent>
            <div class="p-2 h-100">
              <div class="location-map-border">
                @if (showDeviceLog) {
                  <app-device-log class="h-100" [deviceId]="device().id" />
                }
              </div>
            </div>
          </ng-template>
        </ng-container>
      }

      <!-- 
    ** MARK:screenshot
    ** -->
      @if (!responsiveUiService.lg()) {
        <ng-container ngbNavItem>
          <li>
            <a ngbNavLink>{{ 'SCREENSHOT' | translate }}</a>
          </li>

          <ng-template ngbNavContent>
            <ng-container
              [ngTemplateOutlet]="screenShotTemplate"
            ></ng-container>
          </ng-template>
        </ng-container>
      }
    </nav>
    <div [ngbNavOutlet]="nav" class="overflow-auto"></div>
  </ng-template>

  <div class="d-flex pt-2 border-top align-items-end justify-content-end">
    <ng-content select="add-device-button"></ng-content>
    <des-button
      [color]="'danger'"
      [outlineButton]="true"
      [loaderText]="'DEPROVISIONING'"
      (clickAction)="deprovisionDevice()"
      [loading]="deletingDevice()"
      [smallButton]="!responsiveUiService.xl()"
    >
      {{ 'DEPROVISION' | translate }}
    </des-button>

    @if (showForceDeprovision) {
      <des-button
        [color]="'danger'"
        [outlineButton]="true"
        [loaderText]="'DEPROVISIONING'"
        (clickAction)="deprovisionDevice(true)"
        [loading]="deletingDevice()"
        [smallButton]="!responsiveUiService.xl()"
      >
        {{ 'DEPROVISION_FORCE' | translate }}
      </des-button>
    }
  </div>
</div>
