export function gcd(a: number, b: number): number {
  return b === 0 ? a : gcd(b, a % b);
}

export function getAspectRatio(width: number, height: number): string {
  if (!width || !height) {
    return 'NO_DATA';
  }
  const commonDivisor = gcd(width, height);
  const ratioWidth = width / commonDivisor;
  const ratioHeight = height / commonDivisor;
  return `${ratioWidth}:${ratioHeight}`;
}
