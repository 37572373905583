import { enableProdMode } from '@angular/core';
import { environment } from '@desquare/environments';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
  window.console.log = function () {}; // disable any console.log debugging statements in production mode
  window.console.warn = function () {}; // disable any console.warn debugging statements in production mode
  // window.console.error = function () { };
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
