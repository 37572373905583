import { Component, Input } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslatePipe],
  selector: 'app-remove-user-dialog',
  template: `<app-confirm-dialog>
    <p
      [innerHTML]="
        'REMOVE_USER_FROM_PROFILE_PROMPT'
          | translate: { user: user, profile: profile }
      "
    ></p>
  </app-confirm-dialog> `,
})
export class RemoveUserDialogComponent {
  @Input() user = 'USER';
  @Input() profile = 'PROFILE';
}
