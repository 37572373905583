import {
  Component,
  inject,
  input,
  computed,
  ChangeDetectionStrategy,
  output,
} from '@angular/core';
import { LocationsStore } from '@desquare/stores';
import { ILocationForm, ILocation } from '@desquare/interfaces';
import { LocationFormComponent } from '../location-form/location-form.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { DeviceListComponent } from '@designage/app/device/device-list/device-list.component';
import { Point } from 'mapbox-gl';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  standalone: true,
  imports: [
    LocationFormComponent,
    DeviceListComponent,
    NgbNavModule,
    TranslatePipe,
  ],
  selector: 'app-location-manage',
  template: `
    @if (loading()) {
      <div class="progress progress-bar-custom" @showHidePlaylistLoader>
        <div
          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <!-- {{ loaderMessage | translate }} -->
        </div>
      </div>
    }
    <div
      class="px-2 text-truncate d-flex align-items-center justify-content-between sliding-panel-header "
    >
      <label class="d-inline pointer-text">{{ location().name }}</label>
    </div>
    <div class="pt-5 container-fluid content-container-card">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a ngbNavLink>{{ 'DETAILS' | translate }}</a>
          <ng-template ngbNavContent>
            <app-location-form
              [location]="locationForm()"
              (closeSlidingPanel)="closeSlidingPanel.emit()"
            />
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>{{
            'DEVICES_CONNECTED_TO_THIS_LOCATION' | translate
          }}</a>
          <ng-template ngbNavContent>
            <h4 class="py-2">
              {{ 'DEVICES_CONNECTED_TO_THIS_LOCATION' | translate }}
            </h4>
            <app-device-list
              [buttonsHidden]="false"
              [locationId]="location().id!"
            />
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('StatusGaugeAnimation', [
      transition(':enter', [
        style({ margin: '0 -160px 0 0', opacity: '0' }),
        animate('0.25s ease-out', style({ margin: '*', opacity: '1' })),
      ]),
      transition(':leave', [
        style({ margin: '*', opacity: '1' }),
        animate(
          '0.25s ease-in',
          style({ margin: '0 -160px 0 0', opacity: '0' }),
        ),
      ]),
    ]),
    trigger('showHidePlaylistLoader', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.25s ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('0.2s ease-in', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class LocationManageComponent {
  locationsStore = inject(LocationsStore);
  loading = this.locationsStore.loading;
  location = input.required<ILocation>();

  closeSlidingPanel = output();

  loaderMessage!: string;
  locationForm = computed((): ILocationForm => {
    return {
      id: this.location().id,
      name: this.location().name ?? '',
      streetAddress1: this.location().streetAddress1 ?? '',
      streetAddress2: this.location().streetAddress2 ?? '',
      city: this.location().city ?? '',
      zip: this.location().zip ?? '',
      country: this.location().country ?? '',
      phoneNumber: this.location().phoneNumber ?? '',
      region: this.location().region ?? '',
      coordinates: new Point(
        this.location().coordinates?.x ?? 0,
        this.location().coordinates?.y ?? 0,
      ),
    };
  });
}
