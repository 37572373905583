<div class="modal-header">
  <div class="w-100">
    <h4 class="text-center">
      {{ 'DEVICE.DEVICE' | translate }}: {{ device?.name }} -
      {{ statusDay() | date }}
    </h4>
  </div>
  <button
    tabindex="-1"
    type="button"
    class="btn-close btn-close-white"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="modal().dismiss()"
  ></button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    @if (deviceMetrics$ | async; as deviceMetrics) {
      @if (deviceMetrics.status.length > 0) {
        <div class="d-flex align-items-center">
          <designage-echart
            class="w-75"
            [chartType]="ChartType.UPTIME"
            [chartFull]="true"
            [inputData]="deviceMetrics.status"
            [height]="250"
            (onChartClick)="onChartClick($event)"
          />
          <designage-echart
            class="w-100"
            [chartType]="ChartType.UPTIME"
            [inputData]="deviceMetrics.status"
            [dayZoom]="statusDay()"
            [relativeMaxValue]="true"
            [maxValue]="1"
          />
        </div>
      }
      <div class="h-100 overflow-y-auto overflow-x-hidden">
        <div class="d-flex flex-column justify-content-center">
          @if (deviceMetrics.temp.length > 0) {
            <hr />
            <h4 class="text-center">{{ 'SYSTEM_TEMP' | translate }}</h4>
            <designage-echart
              [chartType]="ChartType.INFO"
              [inputData]="deviceMetrics.temp"
              [height]="200"
              [dayZoom]="statusDay()"
              [relativeMinValue]="true"
              [relativeMaxValue]="true"
              [minValue]="2"
              [maxValue]="2"
              [unit]="'°C'"
              [name]="'System Temp'"
            />
          }

          @if (deviceMetrics.cpu.length > 0) {
            <hr />
            <h4 class="text-center">{{ 'CPU_USAGE' | translate }}</h4>
            <designage-echart
              [chartType]="ChartType.INFO"
              [inputData]="deviceMetrics.cpu"
              [height]="200"
              [dayZoom]="statusDay()"
              [relativeMaxValue]="true"
              [maxValue]="5"
              [unit]="'%'"
              [name]="'CPU Usage'"
            />
          }
        </div>
      </div>
    }
  </div>
</div>
<div class="modal-footer">
  {{ 'APPLET_VERSION' | translate }}: {{ device?.deviceInfo?.appletVersion }} |
  {{ 'APPLICATION_TYPE' | translate }}:
  {{ device?.deviceInfo?.applicationType | titlecase }}
  {{ device?.deviceInfo?.applicationVersion }} | {{ 'MODEL' | translate }}:
  {{ device?.deviceInfo?.model }}
</div>
