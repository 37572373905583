import { Component, input, ChangeDetectionStrategy } from '@angular/core';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { Media, ResourceType } from '@designage/gql';
import { CopyToClipboardComponent } from '@desquare/components/common/src/copy-to-clipboard/copy-to-clipboard.component';
import { FileSizePipe } from '@desquare/components/common/src/pipe/file-size/file-size.pipe';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { getAspectRatio } from '@desquare/utils';
import { NumberShortenPipe } from '@desquare/components/common/src/pipe/number-shorten.pipe';
@Component({
  standalone: true,
  imports: [
    CopyToClipboardComponent,
    TranslatePipe,
    FileSizePipe,
    NumberShortenPipe,
    DateProxyPipe,
    DecimalPipe,
    TitleCasePipe,
    NgbPopoverModule,
  ],
  selector: 'app-media-meta-data',
  template: `
    <div class="card mt-3">
      <div class="card-header">
        <h5>{{ 'MEDIA.MEDIA_DETAILS' | translate }}</h5>
      </div>
      <div class="card-body">
        <table class="table table-sm" style="table-layout: fixed;">
          <tr>
            <td style="width: 8rem">{{ 'SECURE_URL' | translate }}</td>
            <td class="d-flex w-100">
              <span class="text-truncate">
                {{ media().metadata?.secureUrl || ('NO_DATA' | translate) }}
              </span>
              @if (media().metadata?.secureUrl) {
                <copy-to-clipboard [cbcontent]="media().metadata?.secureUrl!" />
              }
            </td>
          </tr>

          <tr>
            <td>{{ 'FILE_SIZE' | translate }}</td>
            <td>
              {{
                (media().metadata?.bytes ?? 0 | fileSize) ||
                  ('NO_DATA' | translate)
              }}
              <i
                class="ms-2 ri-information-line"
                triggers="mouseenter:mouseleave"
                placement="end auto"
                [ngbPopover]="fileSizePopover"
                [popoverTitle]="'FILE_SIZE_TOTAL_BYTES_TITLE' | translate"
              ></i>
              <ng-template #fileSizePopover>
                <div style="max-width: 30rem">
                  <div>
                    {{ 'FILE_SIZE_TOTAL_BYTES_DESCRIPTION_1' | translate }}
                  </div>
                  <div class="badge bg-primary">
                    {{ media().metadata?.bytes | fileSize }}
                  </div>
                  <div class="mt-2">
                    {{ 'FILE_SIZE_TOTAL_BYTES_DESCRIPTION_2' | translate }}
                  </div>
                  <div class="badge bg-primary">
                    {{ media().totalBytes | fileSize }}
                  </div>
                </div>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>{{ 'WIDTH' | translate }}</td>
            <td>{{ media().metadata?.width || ('NO_DATA' | translate) }} px</td>
          </tr>
          <tr>
            <td>{{ 'HEIGHT' | translate }}</td>
            <td>
              {{ media().metadata?.height || ('NO_DATA' | translate) }} px
            </td>
          </tr>
          <tr>
            <td>{{ 'ASPECT_RATIO' | translate }}</td>
            <td>
              {{
                media().metadata?.width && media().metadata?.height
                  ? getAspectRatio(
                      media().metadata?.width!,
                      media().metadata?.height!
                    )
                  : ('NO_DATA' | translate)
              }}
            </td>
          </tr>
          <tr>
            <td>{{ 'RESOURCE_TYPE' | translate }}</td>
            <td>
              {{
                media().metadata?.resourceType || ('NO_DATA' | translate)
                  | titlecase
              }}
              (.{{ media().metadata?.format || ('NO_DATA' | translate) }})
            </td>
          </tr>
          @if (media().metadata?.videoMetadata; as videoMetadata) {
            <tr>
              <td colspan="2">
                <h5>{{ 'MEDIA.VIDEO_METADATA' | translate }}</h5>
              </td>
            </tr>
            <tr>
              <td>{{ 'DURATION' | translate }}</td>
              <td>
                {{
                  (this.media().metadata?.duration ?? 0) * 1000 | date: 'mm:ss'
                }}
                ({{ videoMetadata.nbFrames }} {{ 'MEDIA.FRAMES' | translate }})
              </td>
            </tr>

            <tr>
              <td>{{ 'MEDIA.VIDEO_FRAMERATE' | translate }}</td>
              <td>{{ videoMetadata.frameRate ?? 0 | number: '1.0-2' }}</td>
            </tr>
            @if (videoMetadata.video) {
              <tr>
                <td>{{ 'MEDIA.VIDEO_CODEC' | translate }}</td>
                <td>{{ videoMetadata.video.codec }}</td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.VIDEO_BITRATE' | translate }}</td>
                <td>{{ videoMetadata.video.bitRate ?? '' | numberShorten }}</td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.VIDEO_CODEC_PROFILE' | translate }}</td>
                <td>
                  {{ videoMetadata.video.profile }}
                  {{ videoMetadata.video.level }}
                </td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.VIDEO_PIXEL_FORMAT' | translate }}</td>
                <td>{{ videoMetadata.video.pixFormat }}</td>
              </tr>
              <!-- <tr>
            <td>{{ 'MEDIA.VIDEO_TIMEBASE' | translate }}</td>
            <td>{{ videoMetadata.video.timeBase }}</td>
          </tr> -->
            }
            @if (videoMetadata.audio && videoMetadata.audio.codec) {
              <tr>
                <td colspan="2">
                  <h5>{{ 'MEDIA.AUDIO_METADATA' | translate }}</h5>
                </td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.AUDIO_CODEC' | translate }}</td>
                <td>{{ videoMetadata.audio.codec }}</td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.AUDIO_CHANNELS' | translate }}</td>
                <td>
                  {{ videoMetadata.audio.channels }} ({{
                    videoMetadata.audio.channelLayout
                  }})
                </td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.AUDIO_BITRATE' | translate }}</td>
                <td>{{ videoMetadata.audio.bitRate ?? 0 | numberShorten }}</td>
              </tr>
              <tr>
                <td>{{ 'MEDIA.AUDIO_SAMPLE_RATE' | translate }}</td>
                <td>
                  {{ videoMetadata.audio.frequency ?? 0 | numberShorten: 'Hz' }}
                </td>
              </tr>
            }
          }
          <br />
          <tr>
            <td>{{ 'CREATED_AT' | translate }}</td>
            <td>
              {{
                (media().metadata?.createdAt | date: 'short') ||
                  ('NO_DATA' | translate)
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaMetaDataComponent {
  getAspectRatio = getAspectRatio;
  ResourceType = ResourceType;
  media = input.required<Media>();
}
