import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslatePipe],
  selector: 'app-transfer-role-dialog',
  templateUrl: './transfer-role-dialog.component.html',
  styleUrls: ['./transfer-role-dialog.component.scss'],
})
export class TransferRoleDialogComponent {
  @Input() newRoleName = 'this role';
  @Input() displayName = 'this user';

  constructor() {}
}
