import { Component, Input, OnInit } from '@angular/core';
import { IDesignageDataTableColumns, IDeviceLog } from '@desquare/interfaces';
import { DeviceDataService } from '@desquare/services';
import { Observable, scan } from 'rxjs';
import { environment as env } from '@desquare/environments';
import { TranslatePipe } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '../designage-data-table/designage-data-table.component';
import { AsyncPipe } from '@angular/common';

@Component({
  standalone: true,
  imports: [AsyncPipe, TranslatePipe, DesignageDataTableComponent],
  selector: 'app-device-log',
  template: `<div class="row controls">
      <div class="col-12 text-end">
        <button
          class="btn waves-effect waves-light mt-2 me-2 btn-outline-primary"
          (click)="clearLog()"
        >
          {{ 'CLEAR' | translate }}
        </button>
      </div>
    </div>
    <div
      class="channel-list-container container-fluid content-container-card overflow-hidden"
    >
      <designage-data-table
        configId="device-log"
        [data]="(logSource | async) || []"
        [columns]="columns"
        [alwaysSort]="false"
        [showMultiSelect]="false"
        [showFooter]="false"
        [columnSelector]="false"
      >
      </designage-data-table>
    </div> `,
  styleUrls: ['./device-log.component.scss'],
})
export class DeviceLogComponent implements OnInit {
  _deviceId: string | undefined;
  @Input()
  set deviceId(deviceId: string | undefined) {
    this._deviceId = deviceId;
    this.initSubscription();
  }
  get deviceId() {
    return this._deviceId;
  }

  logSource?: Observable<IDeviceLog[]>;

  columns: IDesignageDataTableColumns[] = [
    {
      type: 'string',
      name: 'Type',
      fieldName: 'type',
      style: 'max-width:3rem',
      visible: true,
    },
    {
      type: 'string',
      name: 'Message',
      fieldName: 'message',
      visible: true,
      disableSorting: true,
    },
    {
      type: 'string',
      name: 'Time Stamp',
      fieldName: 'timestamp',
      visible: true,
      style: 'max-width:7rem',
    },
  ];

  constructor(private deviceDataService: DeviceDataService) {}

  ngOnInit(): void {
    if (!env.production) {
      this.activateDeviceLog();
      // activate live log every 57 secs
      setInterval(() => {
        this.activateDeviceLog();
      }, 57000);
    }
  }
  initSubscription() {
    if (this.deviceId) {
      this.logSource = this.deviceDataService
        .getMqttDeviceLog$(this.deviceId)
        ?.pipe(
          scan((acc: IDeviceLog[], curr: IDeviceLog) => [...acc, curr], []),
        );
    }
  }
  /** ask device to send log */
  activateDeviceLog() {
    if (this.deviceId) {
      this.deviceDataService.activateLiveLog(this.deviceId);
    }
  }
  clearLog() {
    // NOTE: the log table will be reset after the first new log coming from the device
    this.initSubscription();
  }
}
