import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { Layout } from '@designage/gql';
import { domConstants, localStorageKeys } from '@desquare/constants';
import {
  CurrentUserService,
  FilterService,
  LayoutDataService,
} from '@desquare/services';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LayoutListComponent } from '../layout-list/layout-list.component';

@Component({
  standalone: true,
  imports: [LayoutListComponent, RouterOutlet, AsyncPipe, TranslatePipe],
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss'],
})
export class LayoutMainComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  layouts$!: Observable<Layout[]>;
  filteredLayouts$!: Observable<Layout[]>;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject('');
  layoutFormGroup!: FormGroup;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  profileId!: string;

  constructor(
    private layoutDataService: LayoutDataService,
    private formBuilder: FormBuilder,
    private filterService: FilterService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
    this.subscribeToFormState();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initForm() {
    this.layoutFormGroup = this.formBuilder.group({
      search: [null],
      pageSize: [this.currentUserService.preferredPageSize || 10],
      page: [1],
    });
  }

  initSubscriptions() {
    /**
     * since this.currentUserService.currentProfile?.id variable
     * is set after ngOnInit() of this component, there will be a timing issue here
     * hence we retrieve from local storage,
     * refactor this when this.currentUserService.currentProfile?.id is fixed
     */
    this.profileId =
      this.currentUserService.currentProfile?.id ??
      this.localStorageService.retrieve(localStorageKeys.ACTIVE_PROFILE_ID);
    if (!this.profileId) return console.error('no profile id');

    // layouts stream
    this.layouts$ = this.layoutDataService
      .getProfileLayoutsObs(this.profileId)
      .pipe(map((result) => result.data.profile?.layouts ?? []));

    // filtered layouts stream
    this.filteredLayouts$ = combineLatest([
      this.layouts$,
      this.filterValue$.asObservable(),
      // this.layoutFormGroup.controls.search.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([list, filterValue]) => {
        return this.filterService.filterListByName(filterValue, list);
      }),
    );
  }

  subscribeToFormState() {
    this.subs.sink =
      this.layoutFormGroup.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          // save current pageSize as preferred page size
          this.currentUserService.setPreferredPageSize(value);

          // reset to first page when page size changes
          this.layoutFormGroup.controls.page.setValue(1);
        },
      );
  }

  onCreateLayoutButtonClicked() {
    // navigate to create layout page
    this.router.navigate(['layouts/create']);
    // this.router.navigate(['create'], {
    //   relativeTo: this.route,
    // });
  }
}
