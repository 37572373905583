<div class="pt-5 container-fluid content-container-card manage-media-container">
  <div class="row">
    <div
      class="text-truncate d-flex align-items-center justify-content-between sliding-panel-header"
    >
      <label class="d-inline pointer-text">
        {{ media().name || ('NO_DATA' | translate) }}
      </label>
    </div>
  </div>
  <div class="card-box p-0">
    <div class="media-manage-grid">
      <div class="text-center">
        @if (isVideo()) {
          <div class="mt-3">
            <cl-media
              mediaType="video"
              [public-id]="publicId()"
              [ngbTooltip]="title() | translate"
              class="cl-media-video"
            ></cl-media>
          </div>
        }
        @if (isRaw()) {
          <img
            [src]="mediaUrl()"
            [alt]="'IMAGE' | translate"
            class="img-fluid screenshot mh-60 p-2"
            [ngbTooltip]="title() | translate"
          />
        }
        @if (isImage()) {
          <cl-media
            mediaType="image"
            [public-id]="publicId()"
            [ngbTooltip]="title() | translate"
            class="img-fluid screenshot mh-60 p-2 cl-media-image"
          ></cl-media>
        }
      </div>

      <div class="d-flex flex-column gap-4 p-3">
        <div class="card">
          <div class="card-body">
            <app-media-settings-form
              [media]="media()"
              (mediaDeleted)="mediaDeleted.emit()"
              [downloadLink]="downloadLink()"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-pills navtab-bg">
              <li class="nav-item">
                <a
                  (click)="currentTab.set(tabs.DETAILS)"
                  [class.active]="currentTab() === tabs.DETAILS"
                  data-toggle="tab"
                  aria-expanded="true"
                  class="nav-link"
                >
                  {{ 'DETAILS' | translate }}
                </a>
              </li>
              @if (media().playlists && media().playlists?.length! > 0) {
                <li class="nav-item">
                  <a
                    (click)="currentTab.set(tabs.PLAYLISTS)"
                    [class.active]="currentTab() === tabs.PLAYLISTS"
                    data-toggle="tab"
                    aria-expanded="true"
                    class="nav-link"
                  >
                    {{ 'MEDIA.MEDIA_USED_IN_PLAYLISTS' | translate }}
                  </a>
                </li>
              }
            </ul>
            @if (currentTab() === tabs.DETAILS) {
              <app-media-meta-data [media]="media()" />
            }
            @if (currentTab() === tabs.PLAYLISTS) {
              @if (usedInPlaylists()) {
                <designage-data-table
                  class="mt-3"
                  [columnSelector]="false"
                  [showFilter]="false"
                  [columns]="desColumns"
                  [data]="usedInPlaylists()"
                  [customComponent]="[name]"
                  [showMultiSelect]="false"
                  [showFooter]="false"
                  [dragDisabled]="true"
                  (rowClick)="onRowClick($event)"
                />
                <ng-template #name let-row>
                  <div [innerHTML]="row.name" class="fs-3"></div>
                </ng-template>
                <p class="py-0 ps-2 fs-6">
                  {{ 'MEDIA.MEDIA_USED_IN_PLAYLISTS_DETAILS' | translate }}
                </p>
              }
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
