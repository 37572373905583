import { Component, OnInit } from '@angular/core';
import { environment } from '@desquare/environments';
import { SessionService } from '@desquare/services';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe],
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
})
export class RegistrationCompleteComponent implements OnInit {
  logoSrc = environment.assets.logoSmall;

  constructor(private sessionService: SessionService) {}

  ngOnInit() {
    console.log('registration complete');
  }

  login() {
    this.sessionService.login();
  }
}
