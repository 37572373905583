import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FormsModule, ConfirmDialogComponent, TranslatePipe],
  selector: 'app-device-remove-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [closeButtonEnabled]="inputField === 'delete'"
    [dismissButtonText]="'CANCEL'"
    [closeButtonText]="'DELETE'"
  >
    <div class="bg-danger text-light p-3 rounded">
      {{ 'DEACTIVATE_DEVICE_MESSAGE' | translate }}
    </div>
    <p class="p-3">
      {{ 'ENTER_DELETE_TO_CONFIRM' | translate }}
    </p>

    <div class="form-group">
      <input
        [(ngModel)]="inputField"
        class="form-control form-control-dark"
        placeholder="delete"
      />
    </div>
  </app-confirm-dialog> `,
})
export class DeviceRemoveDialogComponent {
  @Output() close = new EventEmitter<boolean>();
  inputField?: string;

  onCancel() {
    this.close.emit(false);
  }

  onAccept() {
    this.close.emit(true);
  }
}
