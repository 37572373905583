import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IResourceGroup } from '@desquare/interfaces';
import { ResourceGroupService } from '@desquare/services';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-resource-group-title',
  template: `<input
      #nameInput
      *ngIf="isEditingName"
      class="form-control form-control-dark flex-grow-1 me-2"
      autocomplete="off"
      [(ngModel)]="resourceGroup.name"
      (blur)="endEditName()"
      (keydown.enter)="endEditName()"
      (click)="$event.stopPropagation()"
    />
    <div
      *ngIf="!isEditingName"
      class="d-flex flex-grow-1 align-items-center"
      [class.input-group]="isEditingName"
    >
      <span
        class="me-1 text"
        (click)="startEditName(); $event.stopPropagation()"
      >
        <h5>{{ resourceGroup.name }}</h5>
      </span>
      <i
        (click)="startEditName(); $event.stopPropagation()"
        class="ri-pencil-fill text-primary"
      ></i>
    </div> `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 100%;
      }
    `,
  ],
})
export class ResourceGroupTitleComponent implements OnInit {
  @Input('resourceGroup') resourceGroup!: IResourceGroup;
  @ViewChild('nameInput') nameInput!: ElementRef;

  isEditingName = false;

  nameBeforeEdit?: string;

  constructor(private resourceGroupService: ResourceGroupService) {}

  ngOnInit(): void {}

  startEditName() {
    this.isEditingName = true;

    // save name before edit
    this.nameBeforeEdit = this.resourceGroup.name;

    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  endEditName() {
    this.isEditingName = false;

    const { id, name } = this.resourceGroup;

    // this.resourceGroup.name is used as a ngModel in the template
    const nameAfterEdit = name;

    // don't update name if no changes were made
    if (this.nameBeforeEdit === nameAfterEdit) return;

    this.resourceGroupService
      .updateResourceGroup({
        id,
        name,
      })
      .subscribe();
  }
}
