import {
  Component,
  Input,
  inject,
  computed,
  HostListener,
} from '@angular/core';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  SlidePanelService,
  LocationService,
} from '@desquare/services';
import { IDesignageDataTableColumns, ILocation } from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateLocationDialogComponent } from '../create-location-dialog/create-location-dialog.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { LocationsStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    DesignageDataTableComponent,
    TableDateTimeComponent,
    RouterOutlet,
    TranslatePipe,
  ],
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent {
  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.locationService.refreshLocationsFromApi();
    console.log('location focus');
  }
  locationsStore = inject(LocationsStore);
  profileId = inject(CurrentUserService).getCurrentProfileId();
  locationService = inject(LocationService);

  @Input() enableSlidePanel = true;
  isSlidePanelOpen = inject(SlidePanelService).getIsPanelOpen();
  selectedChannelId = inject(SlidePanelService).getPanelComponentId();
  isConfirmationOpen = false;
  loaderMessage!: string;
  locations = this.locationsStore.locations;
  loading = this.locationsStore.loading;
  hasNoData = computed(() => this.locations.length === 0 && !this.loading);

  desColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'name',
      name: 'NAME',
      type: 'string',
      visible: 'mandatory',
    },
    {
      fieldName: 'streetAddress1',
      name: 'STREET_ADDRESS',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'city',
      name: 'CITY',
      type: 'string',
      visible: true,
      groupable: true,
    },
    {
      fieldName: 'createdAt',
      name: 'CREATED_ON',
      type: 'template',
      templateRef: 'createdAt',
      visible: true,
    },
    {
      fieldName: 'updatedAt',
      name: 'LAST_UPDATED',
      type: 'template',
      templateRef: 'updatedAt',
      visible: true,
    },
  ];

  constructor(
    private toasterService: ToasterService,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private slidePanelService: SlidePanelService,
  ) {}

  get showAddLocationButton() {
    return this.currentUserService.canManageLocations;
  }

  onRowClick(e: unknown) {
    const location: ILocation = e as ILocation;
    if (location?.id && this.profileId) {
      const id = this.encryptionService.encrypt(location.id);
      const profileId = this.encryptionService.encrypt(this.profileId);

      if (this.currentUserService.canManageLocations && this.profileId) {
        this.slidePanelService.setPanelComponentId(location.id);
        this.router.navigate(['manage', id, profileId], {
          relativeTo: this.route,
        });
      } else {
        this.toasterService.error('UNKNOWN_ERROR');
      }
    }
  }

  openCreateLocationDialog() {
    const modalRef = this.modalService.open(CreateLocationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.result.then(() => {}).catch(() => {});
  }
}
