import {
  Component,
  input,
  ChangeDetectionStrategy,
  computed,
  effect,
  inject,
  output,
} from '@angular/core';
import {
  PlaylistStatus,
  Playlist,
  ForcePublishDevicePlaylistGQL,
  PlaylistBulkAction,
  Layout,
} from '@designage/gql';
import { getPlaylistStatus } from '@desquare/utils';
import { PlaylistScheduleStatus } from '@desquare/enums';
import { CurrentUserService, ToasterService } from '@desquare/services';
import moment from 'moment';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { IPlaylistRegion } from '@desquare/interfaces';
import { PlaylistManageDialogComponent } from '@desquare/components/common/src/modals/playlist-manage-dialog.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { lastValueFrom } from 'rxjs';
import { PlaylistsStore } from '@desquare/stores';
import { NgClass } from '@angular/common';
import { SequenceComponent } from '@designage/app/playlist-editor/sequence/sequence.component';
import { OrderByPipe } from '@desquare/components/common/src/pipe/orderBy/orderBy.pipe';
import { parseGeneralScheduleText } from '@desquare/components/common/src/playlist-schedular/ruleParser';
import { parseScheduleRules } from '@desquare/components/common/src/playlist-schedular/ruleParser';
@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslatePipe,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbTooltip,
    SequenceComponent,
    LayoutExplorerComponent,
    DateProxyPipe,
    OrderByPipe,
  ],
  selector: 'app-playlist-row',
  templateUrl: './playlist-row.component.html',
  styleUrls: ['./playlist-row.component.scss'],
  providers: [DateProxyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistRowComponent {
  playlist = input.required<Playlist>();
  layout = input<Layout>();
  playlistRegions = input<IPlaylistRegion[]>();
  playlistStore = inject(PlaylistsStore);
  translate = inject(TranslateService);
  dateTimePipe = inject(DateProxyPipe);

  deviceId = input<string>();
  hideAccordion = input<boolean>(false);

  /** this indicates if the playlist is loaded into device */
  loaded = input<boolean>(false);
  channelId = input<string>();
  /** currently assigned region 
  region = input<string>();
  /** assignable regions */
  showLayoutRegions = input<boolean>(false);

  loading = output<boolean>();
  loaderMessage = output<string>();
  removePlaylistId = output<string>();
  updatePlaylistRegion = output<IPlaylistRegion>();
  updatePlaylist = output<void>();

  startDate = computed(() => this.playlist().schedule?.startDate);
  endDate = computed(() => this.playlist().schedule?.endDate);
  rule = computed((): boolean => {
    const schedule = this.playlist().schedule;
    if (!schedule?.rules) {
      return false;
    }
    return Array.isArray(schedule.rules) && schedule.rules.length > 0;
  });
  playlistSchedule = computed(() => this.playlist().schedule ?? {});

  scheduleAsText = computed(() =>
    parseGeneralScheduleText(
      this.playlistSchedule(),
      this.translate,
      this.dateTimePipe,
    ),
  );
  rulesAsText = computed(() =>
    parseScheduleRules(
      this.playlistSchedule(),
      this.translate,
      this.dateTimePipe,
    ),
  );

  accordionActiveId = 'accordion-playlist-row';
  activityStatus = computed(() =>
    getPlaylistStatus(this.startDate(), this.endDate()),
  );

  playlistNotifications = computed((): boolean => {
    return this.parentReadyToPublish() || this.playlistHasSchedule();
  });

  parentReadyToPublish = computed((): boolean => {
    return (
      this.playlistStore
        .playlists()
        .find((x) => x.id === this.playlist().parentPlaylist?.id)?.status ===
      PlaylistStatus.ReadyToPublish
    );
  });
  playlistHasSchedule = computed((): boolean => {
    return this.startDate() !== null || this.endDate() !== null || this.rule();
  });
  playlistScheduleStatus = computed(
    (): 'ACTIVE' | 'INACTIVE' | 'WAITING' | undefined => {
      if (this.startDate() && this.endDate()) {
        if (
          moment(Date.now()).isBetween(
            moment(this.startDate()),
            moment(this.endDate()),
          )
        )
          return 'ACTIVE';
        if (
          moment(Date.now()).isBefore(moment(this.startDate())) &&
          moment(Date.now()).isBefore(moment(this.endDate()))
        )
          return 'WAITING';
        if (
          moment(Date.now()).isAfter(moment(this.startDate())) &&
          moment(Date.now()).isAfter(moment(this.endDate()))
        )
          return 'INACTIVE';
      }
      if (this.startDate()) {
        return moment(Date.now()).isBefore(moment(this.startDate()))
          ? 'WAITING'
          : 'ACTIVE';
      }
      if (this.endDate()) {
        return moment(Date.now()).isAfter(moment(this.endDate()))
          ? 'INACTIVE'
          : 'ACTIVE';
      }
      return undefined;
    },
  );

  get playlistIsLoadedByDevice() {
    return this.deviceId() && this.loaded();
  }
  get isForcePublishShown() {
    // TODO: work on feedback from device playlist load
    return false; // this.isUserSuperAdmin && !this.playlistIsLoadedByDevice;
  }

  get devicePlaylistStatus() {
    return !this.playlistIsLoadedByDevice
      ? 'OUT_OF_SYNC'
      : this.playlist().status;
  }

  get statusSuccess() {
    return (
      this.devicePlaylistStatus === PlaylistStatus.Published ||
      this.devicePlaylistStatus === PlaylistStatus.Active
    );
  }
  get statusDanger() {
    return !this.statusSuccess;
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  get scheduleStatus() {
    let ret = PlaylistScheduleStatus.NOT_SCHEDULED;
    if (this.startDate() || this.endDate()) {
      const yesterday = moment(Date.now()).add(-1, 'd');
      const tomorrow = moment(Date.now()).add(1, 'd');
      if (
        moment(Date.now()) < moment(this.startDate() || tomorrow) || // not yet started
        moment(Date.now()) > moment(this.endDate() || yesterday) // already ended
      ) {
        ret = PlaylistScheduleStatus.SCHEDULED;
      } else {
        ret = PlaylistScheduleStatus.PLAYING_NOW;
      }
      return ret;
    }
    return ret;
  }

  layoutRegions = computed(
    () =>
      this.layout()
        ?.source?.regionBlocks?.map((x) => x?.regionName)
        .filter((x) => x !== '')
        .sort() || [],
  );

  get isDefaultRegion() {
    return (this.playlist().region || '') === '';
  }
  get canChangeRegion() {
    return this.layoutRegions()?.length > 0 || this.mustUpdateRegion;
  }

  /** current region is NOT compatible with layout regions list */
  get mustUpdateRegion() {
    const region = this.playlist().region || '';
    return this.isDefaultRegion
      ? false
      : ((this.layoutRegions() || []).length === 0 && region !== '') ||
          (this.layoutRegions()?.length > 0 &&
            !this.layoutRegions()?.includes(region));
  }
  constructor(
    private currentUserService: CurrentUserService,
    private forcePublishDevicePlaylist: ForcePublishDevicePlaylistGQL,
    private toasterService: ToasterService,
    private modalService: NgbModal,
  ) {
    effect(() => {
      // console.log('playlist-row', this.playlist().name, this.playlist().region);
    });
  }

  redirectToPage() {
    const parentPlaylistId = this.playlist().parentPlaylist?.id;
    if (!parentPlaylistId) {
      console.error('No parent playlist to edit');
      return;
    }
    const modal = this.modalService.open(PlaylistManageDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    const componentInstance =
      modal.componentInstance as PlaylistManageDialogComponent;
    componentInstance.playlistId =
      this.playlist().parentPlaylist?.id || this.playlist().id;

    modal.result
      .then(
        (result) => {
          this.updatePlaylist.emit();
        },
        (reason) => {},
      )
      .catch((err) => console.error('error', err));
  }

  async forcePublish() {
    this.loading.emit(true);
    this.loaderMessage.emit('FORCE_PUBLISHING_PLAYLIST');

    await lastValueFrom(
      this.forcePublishDevicePlaylist.mutate({
        ids: [this.playlist().parentPlaylist?.id || this.playlist().id],
        action: PlaylistBulkAction.PublishSelected,
        input: {
          deviceIds: [this.deviceId() || ''],
        },
      }),
    ).then(({ data }) => {
      if (data) {
        this.toasterService.success('FORCE_PUBLISH_SUCCESS');
      } else {
        this.toasterService.error('FORCE_PUBLISH_FAIL');
      }
      this.loading.emit(false);
    });
  }

  removePlaylist() {
    this.removePlaylistId.emit(this.playlist().id);
  }

  setRegion(region: string) {
    console.log('setRegion', region);
    this.updatePlaylistRegion.emit({
      playlistId: this.playlist().id,
      region,
    });
  }
  resetRegion() {
    this.setRegion('');
  }
}
