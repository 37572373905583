import {
  Component,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  computed,
  signal,
  input,
  Output,
  EventEmitter,
  effect,
} from '@angular/core';
import {
  ResourceType,
  UpdateMediaSubscriptionGQL,
  Media,
  PlaylistLink,
} from '@designage/gql';
import { PlaylistService, SlidePanelService } from '@desquare/services';
import { environment } from '@desquare/environments';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';
import { MediaSettingsFormComponent } from '../media-settings-form/media-settings-form.component';
import { MediaMetaDataComponent } from '../media-meta-data/media-meta-data.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MediaStore } from '@desquare/stores';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { PlaylistManageDialogComponent } from '@desquare/components/common/src/modals/playlist-manage-dialog.component';

enum Tabs {
  SETTINGS,
  DETAILS,
  PLAYLISTS,
}
@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslatePipe,
    CloudinaryMediaComponent,
    DesignageDataTableComponent,
    MediaSettingsFormComponent,
    MediaMetaDataComponent,
    NgbTooltip,
  ],
  selector: 'app-media-manage',
  templateUrl: './media-manage.component.html',
  styleUrls: ['./media-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaManageComponent {
  mediaStore = inject(MediaStore);
  slidePanelService = inject(SlidePanelService);
  destroyRef = inject(DestroyRef);
  modalService = inject(NgbModal);
  playlistService = inject(PlaylistService);
  @Output() mediaDeleted = new EventEmitter();

  media = input.required<Media>();

  currentTab = signal<Tabs>(Tabs.DETAILS);
  loaderMessage!: string;
  loading = signal(true);
  tabs = Tabs;
  mediaId = signal<string>('');
  downloadLink = computed(() => this.media().secureUrl);
  publicId = computed(() => this.media().publicId);
  isImage = computed(() => this.media().type === ResourceType.Image);
  isVideo = computed(() => this.media().type === ResourceType.Video);
  isRaw = computed(() => this.media().type === ResourceType.Raw);

  updateMediaSubscriptionSignal = toSignal(
    inject(UpdateMediaSubscriptionGQL).subscribe(),
  );

  desColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'name',
      name: 'PLAYLIST_NAME',
      type: 'template',
      templateRef: 'name',
      visible: 'mandatory',
    },
  ];
  placeHolderImage = environment.assets.placeholderImage;

  title = computed(() => {
    if (!this.media().metadata) {
      return 'METADATA_MISSING_ERROR';
    } else {
      return this.media().name;
    }
  });
  usedInPlaylists = computed((): PlaylistLink[] => {
    const playlists = this.media().playlists || [];

    const countedPlaylists: {
      [id: string]: { count: number; playlist: PlaylistLink };
    } = playlists.reduce(
      (
        acc: { [id: string]: { count: number; playlist: PlaylistLink } },
        playlist,
      ) => {
        const id = playlist.parentPlaylistId || playlist.id;
        if (!id) {
          return acc; // Skip playlists without an ID
        }

        if (acc[id]) {
          acc[id].count++;
        } else {
          acc[id] = { count: 1, playlist: { ...playlist } }; // Create a copy to avoid modifying the original
        }
        return acc;
      },
      {},
    );

    return Object.values(countedPlaylists).map(({ count, playlist }) => {
      if (count > 1) {
        return {
          ...playlist,
          name: `<div class="fs-5 fw-bold">${playlist.name} <br/> <span class="badge bg-success mt-2 py-0 fw-bold fs-6">media used ${count} times in this playlist</span></div>`,
        };
      } else {
        return {
          ...playlist,
          name: `<div class="fs-5 fw-bold">${playlist.name} </div>`,
        };
      }
    });
  });

  constructor(private updateMediaSubscription: UpdateMediaSubscriptionGQL) {
    effect(() => {
      console.log('mediaManage', this.media());
    });
  }
  mediaUrl = computed(() => {
    if (!this.media().metadata) {
      return environment.assets.missingImagePlaceholder;
    } else {
      return environment.assets.placeholderImage;
    }
  });

  get isMetadataAvailable() {
    return this.media().metadata;
  }

  initMedia(mediaId: string) {
    this.loaderMessage = 'FETCHING_MEDIA';

    this.updateMediaSubscription
      .subscribe({ id: mediaId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ data }) => {
        if (data?.updateMedia?.media) {
          console.log('updateMedia', data);
          const oldMedia = this.media();
          const newMedia = data.updateMedia.media;
          if (!newMedia.metadata) {
            newMedia.metadata = oldMedia?.metadata;
          }
        }
      });
  }
  onRowClick(e: unknown) {
    const playlist = e as PlaylistLink;

    const id = playlist.parentPlaylistId || playlist.id;
    if (!id) {
      console.error('No playlist to edit');
      return;
    }
    const modal = this.modalService.open(PlaylistManageDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    const componentInstance =
      modal.componentInstance as PlaylistManageDialogComponent;

    componentInstance.playlistId = id;

    modal.result
      .then(
        (result) => {
          this.playlistService.refreshPlaylistsFromApi();
        },
        (reason) => {},
      )
      .catch((err) => console.error('error', err));
  }
}
