import { Component } from '@angular/core';
import { Playlist } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe, ConfirmDialogComponent],
  selector: 'app-channel-remove-playlists-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [dismissButtonText]="'CANCEL'"
    [closeButtonText]="'REMOVE'"
  >
    <p>
      {{ 'REMOVE_PLAYLIST_FROM_CHANNEL_CONFIRMATION' | translate }}
    </p>
    <ul class="list-group list-group-flush mt-2">
      @for (playlist of playlists; track playlist.id) {
        <li class="list-group-item text-white border-0">{{ playlist.name }}</li>
      }
    </ul>
  </app-confirm-dialog> `,
})
export class ChannelRemovePlaylistsDialogComponent {
  playlists: Playlist[] = [];
}
