import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IPlaylistForm } from '@desquare/interfaces';
import {
  CreatePlaylistGQL,
  Playlist,
  PlaylistSource,
  PlaylistStatus,
  PlaylistType,
  SaveOption,
} from '@designage/gql';
import { SubSink } from 'subsink';
import {
  ToasterService,
  WindowService,
  PlaylistEditorService,
  EncryptionService,
  CurrentUserService,
} from '@desquare/services';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { TranslatePipe } from '@ngx-translate/core';
import { PlaylistFormNewComponent } from '@designage/app/playlist-editor/playlist-form-new/playlist-form-new.component';

@Component({
  standalone: true,
  imports: [TranslatePipe, PlaylistFormNewComponent],
  selector: 'app-playlist-creation',
  template: `
    @if (loading) {
      <div class="progress" style="height: 32px">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style="width: 100%"
          aria-valuenow="loadPercent"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span class="me-2">{{ loaderMessage | translate }}</span>
        </div>
      </div>
    }

    <div
      [class.disabled]="loading"
      class="pt-3 container-fluid content-container-card playlist-creation-container"
    >
      <designage-playlist-form-new [createPlaylist]="true" />
      <!-- <app-playlist-form
        [isCreateNewAsset]="true"
        [nameRequired]="nameRequired"
        [publishDestinationRequired]="channelRequired"
        (valid)="isFormValid = $event"
        (pristine)="isFormPristine = $event"
        (loading)="isFormLoading = $event"
        [playlist]="newPlaylist"
        (values)="form = $event"
        (userAction)="onUserAction($event)"
      /> -->
    </div>
  `,
  styles: [
    `
      .disabled {
        pointer-events: none;
      }
    `,
  ],
})
export class PlaylistCreationComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  @Input() externalLoading!: boolean;

  playlistType: PlaylistType = PlaylistType.Scheduled;

  loading!: boolean;
  loaderMessage!: string;
  isFormValid!: boolean;
  isFormPristine!: boolean;
  isFormLoading!: boolean;
  isAssetSelectionLoading!: boolean;
  formLoaderMessage!: string;
  form!: IPlaylistForm;
  saveOptions = SaveOption;
  nameRequired!: boolean;
  channelRequired!: boolean;

  newPlaylist: Playlist = {
    id: '',
    name: '',
    assets: [],
    channelGroups: [],
    channels: [],
    createdAt: null,
    locations: [],
    profileId: '',
    source: PlaylistSource.Cms,
    status: PlaylistStatus.Draft,
    type: PlaylistType.Scheduled,
    user: null,
    updatedAt: '',
    channelRegions: [],
    childPlaylists: [],
  };

  constructor(
    private createPlaylistGQL: CreatePlaylistGQL,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private playlistEditorService: PlaylistEditorService,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService,
  ) {}

  get saveButtonEnabled() {
    return this.isFormValid && !this.isFormPristine && !this.isFormLoading;
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.subs.sink = this.route.params.subscribe((params) => {
      const encryptedType = params.type;
      if (encryptedType) {
        const type = this.encryptionService.decrypt(encryptedType);

        try {
          this.playlistType = type as PlaylistType;
          this.playlistEditorService.playlistType = this.playlistType;
        } catch {
          console.error(`ERROR: cannot create playlist with type ${type}`);
          this.playlistType = PlaylistType.Scheduled;
        }
      }
    });

    this.externalLoading = false;
    this.loading = false;
    this.loaderMessage = 'LOADING';
    this.isFormValid = false;
    this.isFormPristine = true;
    this.isFormLoading = false;
    this.isAssetSelectionLoading = false;
    this.formLoaderMessage = 'LOADING';
    this.nameRequired = false;
    this.channelRequired = false;
    // Set active playlist to null
    this.localStorageService.store(localStorageKeys.ACTIVE_PLAYLIST_ID, null);
  }

  createPlaylist(saveOption: SaveOption) {
    let invalidForm = false;
    if (!this.form.name) {
      this.nameRequired = true;
      invalidForm = true;
    }
    if (saveOption === SaveOption.Publish && !this.form.channelIds?.length) {
      this.channelRequired = true;
      invalidForm = true;
    }
    if (invalidForm) {
      return;
    }

    this.loading = true;
    if (saveOption === SaveOption.Draft) {
      this.loaderMessage = 'CREATING_PLAYLIST';
    }
    if (saveOption === SaveOption.Publish) {
      this.loaderMessage = 'PUBLISHING_PLAYLIST';
    }
    this.windowService.scrollToTop();

    const profileId = this.currentUserService.getCurrentProfileId();
    const input = this.playlistEditorService.getGqlCreateInput(
      this.form,
      profileId,
    );
    input.type = this.playlistType;
    input.saveOption = saveOption;

    this.subs.sink = this.createPlaylistGQL
      .mutate({ input }, { fetchPolicy: 'no-cache' })
      .subscribe(({ data }) => {
        if (data) {
          const { isSuccessful, playlist } = data.createPlaylist;
          if (isSuccessful && playlist) {
            this.toasterService.success('CREATE_PLAYLIST_SUCCESS');
            this.playlistEditorService.deletePendingPlaylist({
              profileId,
            });
            this.playlistEditorService.playlistSaveChanges.emit(true);
            const id = this.encryptionService.encrypt(playlist.id);
            if (this.currentUserService.canManagePlaylist && id) {
              this.router.navigate(['playlists/manage', id]);
              // this.router.navigate(['/playlist/manage', id]);
            }
          } else {
            this.toasterService.error('CREATE_PLAYLIST_ERROR');
          }
          this.loading = false;
        }
      });
  }

  onUserAction(action: string) {
    if (action === 'PUBLISH') {
      this.createPlaylist(SaveOption.Publish);
    }

    if (action === 'SAVE') {
      this.createPlaylist(SaveOption.Draft);
    }
  }
}
