import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  computed,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PlaylistListComponent } from '@designage/app/playlist/playlist-list/playlist-list.component';
import { Layout, Playlist } from '@designage/gql';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslatePipe,
    NgbDropdownModule,
    LayoutExplorerComponent,
    PlaylistListComponent,
    FormDialogComponent,
  ],
  selector: 'designage-add-playlist-dialog',
  templateUrl: './add-playlist-dialog.component.html',
  styleUrls: ['./add-playlist-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPlaylistDialogComponent implements OnInit {
  modal = inject(NgbActiveModal);
  playlistList = viewChild<PlaylistListComponent>('playlistList');
  // @ViewChild('playlistList') playlistList!: PlaylistListComponent;

  @Input() channelId!: string;
  @Input() hidePlaylistIds: string[] = [];
  @Input() selectedRegionInput!: string;
  // selectedRegionInput = input<string | null>();
  layout = input<Layout>();
  selectedRegion = signal<string>('');
  showLayoutRegions = computed(() => this.layoutRegions()?.length > 0);
  layoutRegions = computed(
    () => this.layout()?.source?.regionBlocks?.map((x) => x?.regionName) || [],
  );
  selectedPlaylists = signal<Playlist[]>([]);

  selectedPlaylistsIds = computed(() =>
    this.selectedPlaylists().map((x) => x.id),
  );
  selectedPlaylistPreview = computed(() =>
    this.selectedPlaylists().length > 0
      ? this.selectedPlaylists()[0].previewContent
      : undefined,
  );
  isDefaultRegion = computed(
    () => !this.selectedRegion() || this.selectedRegion() === '',
  );

  ngOnInit(): void {
    this.selectedRegion.set(this.selectedRegionInput);
  }

  setRegion(region: string) {
    this.selectedRegion.set(region);
  }
}
